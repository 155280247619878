import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BuilderService } from '@builder.io/angular';
import { GetContentOptions } from '@builder.io/sdk';

import { Meta } from '@angular/platform-browser';

import { environment } from '../environments/environment';
import { getSiteModel } from './app-env';

@Component({
	selector: 'builder-route',
	templateUrl: './builder-route.component.html',
	styleUrls: ['./builder-route.component.scss'],
	providers: [BuilderService]
})
export class BuilderRouteComponent {

	@Input()
	path!: string;

	@Input()
	model!: string;

	@Input()
	publicApiKey!: string;

	content: any;

	windowData: any;

	contentOptions: any = { noTraverse: false };

	private _siteModel: any;
	private _segments!: any[];

	constructor(route: ActivatedRoute, router: Router, private _builder: BuilderService, private _meta: Meta) {

		if (!this.path) {
			route.url.subscribe((segments) => {
				this._segments = segments;
				this.path = '/' + segments.map((s) => s.path).join('/');
			});
		}

		this._siteModel = getSiteModel();
		if (
			this.path.indexOf('/checkout') > -1 ||
			this.path.indexOf('/myaccount') > -1 ||
			this.path.indexOf('/gifts-blog') > -1 ||
			this.path.indexOf('/where-is-my-order') > -1
		) {
			if (this.path.indexOf('/where-is-my-order') > -1) // specific for where is my order
			{
				this.path = '/myaccount/where-is-my-order' + window.location.search;
			}

			let newPage = `https://${this._siteModel.domain}${this.path}`;
			setTimeout(function(){document.location.href = newPage;},250);
			return;
		}

		if (!this.model) {
			if (this._siteModel) {
				this.model = this._siteModel.templateKey;
				if (!this.publicApiKey) {
					this.publicApiKey = this._siteModel.publicApiKey;
				}
			}
		}
	}

	ngOnInit() {
		if (environment && this._siteModel) {
			this.loadContent();
		}
	}

	private loadContent() {

		this._builder.get(this.model, {
			url: this.path,
			cachebust: environment && environment['cachebust']
		}).subscribe((result) => {
			if (result) {
				this.content = result.data;
			} else {
				console.log('Page not found', '\nresult.data:', result.data);
				location.href = '/404-page-not-found';
			}

		}, (err) => {
			console.error(err);
			location.href = '/404-page-not-found';
		});
	}

	prefixWithDomain(str: string) {
		if (str && str.indexOf('/') === 0) {
			str = `https://${this._siteModel.domain}${str}`;
		}
		return str;
	}

	// modifyResults(json) {
	// 	if (json.SiteUrl && json.SiteUrl.RESULT && json.SiteUrl.RESULT.length) {
	// 		json.SiteUrl.RESULT.forEach((result) => {
	// 			result.SITE_LOGO_ANDROID = this.prefixWithDomain(result.SITE_LOGO_ANDROID);
	// 			result.SITE_LOGO_FAVICON = this.prefixWithDomain(result.SITE_LOGO_FAVICON);
	// 			result.SITE_LOGO_IOS = this.prefixWithDomain(result.SITE_LOGO_IOS);
	// 			result.SITE_LOGO_PRIMARY = this.prefixWithDomain(result.SITE_LOGO_PRIMARY);
	// 		});
	// 	}
	// 	return json;
	// }

	// buildMeta(jsonData) {
	// 	if (!jsonData) {
	// 		return;
	// 	}

	// 	if (jsonData.SiteUrl && jsonData.SiteUrl.SUCCESS === 1) {
	// 		const siteData = jsonData.SiteUrl.RESULT[0];

	// 		if (siteData.SITE_NAME) {
	// 			document.title = siteData.SITE_NAME;
	// 			if (!jsonData.PageUrl || !jsonData.PageUrl.SUCCESS) {
	// 				this._meta.addTag({ name: 'title', content: siteData.SITE_NAME });
	// 				this._meta.addTag({ name: 'og:title', content: siteData.SITE_NAME });
	// 				// console.log('_meta title & og:title', siteData.SITE_NAME);
	// 			}
	// 		}
	// 	}

	// 	if (jsonData.PageUrl && jsonData.PageUrl.SUCCESS === 1) {
	// 		const pageData = jsonData.PageUrl.RESULT;

	// 		// Set window title / title tags
	// 		if (pageData.PAGE_TITLE) {
	// 			document.title = pageData.PAGE_TITLE;
	// 			this._meta.addTag({ name: 'title', content: pageData.PAGE_TITLE });
	// 			this._meta.addTag({ name: 'og:title', content: pageData.PAGE_TITLE });
	// 			// console.log('_meta title & og:title', pageData.PAGE_TITLE);
	// 		}

	// 		// Set Meta Description
	// 		if (pageData.META_DESC) {
	// 			this._meta.addTag({ name: 'description', content: pageData.META_DESC });
	// 			// console.log('_meta description', pageData.PAGE_TITLE);
	// 		}

	// 		// Set Meta keywords
	// 		if (pageData.META_KEYWORDS) {
	// 			this._meta.addTag({ name: 'keywords', content: pageData.META_KEYWORDS });
	// 			// console.log('_meta keywords', pageData.META_KEYWORDS);
	// 		}
	// 	}
	// }

	contentLoaded($event: any) {
		// console.log('BuilderRouteComponent', 'loaded', $event);
		// console.log($event.data);
		// window['json_data'] = this.windowData;
		// console.log('contentLoaded');
	}

	contentError($event: any) {
		console.log('BuilderRouteComponent', 'error', $event);
	}
}
