import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { environment } from '../environments/environment';

declare let gtag: Function;
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(private _router: Router, private elementRef: ElementRef<HTMLElement>, private renderer: Renderer2) {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd && typeof gtag !== 'undefined') {
        gtag('config', environment.uaGoogleA, {
        'page_path': event.urlAfterRedirects
        });
      }
    });
  }

  ngOnInit(): void {
    if (environment.production) {
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'ng-version');
    }
  }
}
