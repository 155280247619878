import { BuilderModule } from '@builder.io/angular';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BuilderRouteComponent } from './builder-route.component';

import { getSiteModel } from './app-env';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ItemComponent } from './Item/item.component';
import { Builder } from '@builder.io/sdk';

const siteModel = getSiteModel();

Builder.isStatic = true;
@NgModule({
	declarations: [AppComponent, BuilderRouteComponent],
	imports: [
		CommonModule,
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		BuilderModule.forRoot(siteModel.publicApiKey),
		AppRoutingModule,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
