// import { environment } from '../environments/environment';

// The public key used to fetch components from the builder API
export const KEY_GIFT_TREE = 'gift-tree';
export const KEY_GEO = 'geo';
export const KEY_WINE_BASKETS = 'wine-baskets';
export const KEY_SHIP_ME_WINE = 'ship-me-wine';

// A map of builder.io models -> domain names
// i.e gifttree.com/net/org/test use the builder.io 'gift-tree' model
export const DOMAIN_TEMPLATE_MAP: any = {
    [KEY_GIFT_TREE]: {
        publicApiKey: '2688bb369e1049dfaf70b9e16011986c',
        sites: [
            { name: 'gifttree', tld: 'com' }
        ],
    },
    [KEY_GEO]: {
        publicApiKey: 'a5e2c8181972459cb5c7a1b0823e7944',
        sites: [
            { name: 'aaafruitbaskets', tld: 'com' },
            { name: '1stfloristsofhouston', tld: 'com' },
            { name: '1stfloristsofportland', tld: 'com' },
            { name: '1stopatlantaflorists', tld: 'com' },
            { name: '1stopflorists', tld: 'com' },
            { name: '1stopnewjerseyflorists', tld: 'com' },
            { name: '1stopsanjoseflorists', tld: 'com' },
            { name: 'a-dallas-florists', tld: 'com' },
            { name: 'akronflowers', tld: 'com' },
            { name: 'alamoflowerssanantonio', tld: 'com' },
            { name: 'arkansasfloristflowers', tld: 'com' },
            { name: 'austinflorists', tld: 'com' },
            { name: 'bakersfieldflowers', tld: 'com' },
            { name: 'best-baltimore-florists', tld: 'com' },
            { name: 'birminghamflowers', tld: 'com' },
            { name: 'bostonflowernballoons', tld: 'com' },
            { name: 'californiaflowershop', tld: 'com' },
            { name: 'cantonflowers', tld: 'com' },
            { name: 'charlestonflowers', tld: 'com' },
            { name: 'charlotteflowers', tld: 'com' },
            { name: 'chicagoflowersource', tld: 'com' },
            { name: 'clevelandflowers', tld: 'com' },
            { name: 'columbusflowershop', tld: 'com' },
            { name: 'daytonflowers', tld: 'com' },
            { name: 'denver-florists', tld: 'com' },
            { name: 'detroitfloristsonline', tld: 'com' },
            { name: 'floridaflorists', tld: 'com' },
            { name: 'floristsinsacramento', tld: 'com' },
            { name: 'flowers-n-florists', tld: 'com' },
            { name: 'kansasflorists', tld: 'com' },
            { name: 'lasvegas-florists', tld: 'com' },
            { name: 'madisonflowers', tld: 'com' },
            { name: 'maineflorists', tld: 'com' },
            { name: 'miamiflowers', tld: 'com' },
            { name: 'miamiflowersnballoons', tld: 'com' },
            { name: 'minneapolisflowers', tld: 'com' },
            { name: 'montgomeryflowers', tld: 'com' },
            { name: 'newarkflorists', tld: 'com' },
            { name: 'newyorksfinestflowers', tld: 'com' },
            { name: 'orlandoflowersngifts', tld: 'com' },
            { name: 'philadelphiaflowers', tld: 'com' },
            { name: 'phoenixroseflowers', tld: 'com' },
            { name: 'pittsburghflowershop', tld: 'com' },
            { name: 'providenceflowers', tld: 'com' },
            { name: 'sandiegoflowers', tld: 'com' },
            { name: 'sanfranciscoflorists', tld: 'com' },
            { name: 'seattle-florists', tld: 'com' },
            { name: 'suncoastflowers', tld: 'com' },
            { name: 'syracuseflowers', tld: 'com' },
            { name: 'top-atlanta-florists', tld: 'com' },
            { name: 'utahflorists', tld: 'com' },
            { name: 'washingtondcflowers', tld: 'com' },
            { name: 'westpalmbeachflowers', tld: 'com' },
            { name: 'provoflorists', tld: 'com' },
            { name: '1st-arizona-florists', tld: 'com' },
            { name: '1st-nashville-florists', tld: 'com' },
            { name: '1st-new-orleans-florists', tld: 'com' },
            { name: '1stop-tucson-florists', tld: 'com' },
            { name: '1st-tampa-florists', tld: 'com' },
            { name: '1stop-manhattan-florists', tld: 'com' },
            { name: '1stop-memphis-florists', tld: 'com' },
            { name: 'spokane-florists', tld: 'com' },
            { name: 'boise-florists', tld: 'com' },
            { name: 'richmond-florists', tld: 'com' },
            { name: 'reno-florists', tld: 'com' },
            { name: 'oakland-florists', tld: 'com' },
            { name: 'tacoma-florists', tld: 'com' },
            { name: 'louisville-florists', tld: 'com' },
            { name: 'buffalo-florists', tld: 'com' },
            { name: 'cincinnati-florists', tld: 'com' },
            { name: 'mesa-florists', tld: 'com' },
            { name: 'mobile-florists', tld: 'com' },
            { name: 'milwaukee-florists', tld: 'com' },
            { name: 'scottsdale-florists', tld: 'com' },
            { name: 'wichita-florists', tld: 'com' },
            { name: 'corpus-christi-florists', tld: 'com' },
            { name: 'sarasota-florists', tld: 'com' },
            { name: 'el-paso-florists', tld: 'com' },
            { name: 'jacksonville-florists', tld: 'com' },
            { name: 'rochester-florists', tld: 'com' },
            { name: 'flowerfast', tld: 'com' },
            { name: 'naples-florists', tld: 'com' },
            { name: 'boiseflorists', tld: 'com' },
            { name: 'sunshine-florists', tld: 'com' },
            { name: 'fantasticflower', tld: 'com' },
            { name: '800-send-floral', tld: 'com' },
            { name: 'knoxville-florist-shop', tld: 'com' },
            { name: 'raleigh-florist-shop', tld: 'com' },
            { name: 'little-rock-florist', tld: 'com' },
            { name: 'chattanooga-florist', tld: 'com' },
            { name: 'ft-lauderdale-florists', tld: 'com' },
            { name: 'oklahoma-city-florist', tld: 'com' },
            { name: 'indianapolis-florist-shop', tld: 'com' },
            { name: 'annarbor-flowers-1', tld: 'com' },
            { name: 'durham-flowers-now', tld: 'com' },
            { name: 'evansville-florists', tld: 'com' },
            { name: 'fayetteville-flowers-now', tld: 'com' },
            { name: 'lancaster-flowers-fast', tld: 'com' },
            { name: 'lansing-flowers-now', tld: 'com' },
            { name: 'ocala-flowers', tld: 'com' },
            { name: 'peoria-florists', tld: 'com' },
            { name: 'lincoln-florists', tld: 'com' },
            { name: 'city-blooms', tld: 'com' },
        ],
    },
    [KEY_WINE_BASKETS]: {
        publicApiKey: 'a33bd09b12364645bea55c87dfd9ea97',
        sites: [
            { name: 'winebaskets', tld: 'com' }
        ],
    },
    [KEY_SHIP_ME_WINE]: {
        publicApiKey: '485ffca919e741bba7253621fa2672d1',
        sites: [
            { name: 'shipmewine', tld: 'com' }
        ],
    },
};

// The default backup template
const DEFAULT_TEMPLATE = { templateKey: 'gift-tree', domain: 'www.gifttree.com', publicApiKey: DOMAIN_TEMPLATE_MAP[KEY_GIFT_TREE].publicApiKey };

// Retrieves the builder.io model from the map above, based on the current domain.
export const getSiteModel = (): any => {
    // const host = window.location.hostname.toLowerCase();
    const host = 'www.gifttree.com';
    const templateKeys = Object.keys(DOMAIN_TEMPLATE_MAP);
    for (const templateKey of templateKeys) {
        const template = DOMAIN_TEMPLATE_MAP[templateKey];
        const siteCombinations = [...template.sites.map((site: { name: any; tld: any; }) => ({
            name: site.name,
            domain: `www.${site.name}.${site.tld}`,
            matches: [
                `${site.name}.${site.tld}`, // real site
                `web.${site.name}.lvdev.net`, // dev site (buildable)
                `web-qa.${site.name}.lvdev.net`, // qa site (buildable)
                `${site.name}.gifttree.lvdev.net`, // reworked domain due to handling so many (buildable)
                `${site.name}.test`, // local hosts file redirect site
            ]
        })).flat()];
        const site = siteCombinations.find((site) => site.matches.find((m: string) => host.includes(m)));
        if (site) {
            return { templateKey, domain: site.domain, siteName: site.name, publicApiKey: template.publicApiKey };
        }
    }
    console.error(`No template found... defaulting to DEFAULT_TEMPLATE (${DEFAULT_TEMPLATE})`);
    return DEFAULT_TEMPLATE;
};
